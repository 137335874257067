<template>
  <center-content columns="col-md-10 col-sm-12">
    <base-card>
      <template #header>يرجى الإنتباه</template>
      <template #body>
        <h5>
          يرجى التواصل مع الإدارة لتجديد بيانات حسابك
        </h5>
        <h6>
          للإستفسار يرجى&nbsp;<router-link :to="{ name: 'contact' }"
            >التواصل</router-link
          >&nbsp;مع الإدارة
        </h6>
      </template>
    </base-card>
  </center-content>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
import CenterContent from "@/components/UI/CenterContent";

export default {
  components: { CenterContent, BaseCard },
};
</script>
